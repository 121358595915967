@font-face {
	font-family: "HDColtonMedium";
	src: url("../fonts/HDColton-Medium.woff2") format("woff2"),
		url("../fonts/HDColton-Medium.woff") format("woff"),
		url("../fonts/HDColton-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "HDColtonRegular";
	src: url("../fonts/HDColton-Regular.woff2") format("woff2"),
		url("../fonts/HDColton-Regular.woff") format("woff"),
		url("../fonts/HDColton-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "HDColtonSemiBold";
	src: url("../fonts/HDColton-Semibold.woff2") format("woff2"),
		url("../fonts/HDColton-Semibold.woff") format("woff"),
		url("../fonts/HDColton-Semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'HDColtonBold';
    src: url('../fonts/HDColton-Bold.woff2') format('woff2'),
        url('../fonts/HDColton-Bold.woff') format('woff'),
        url('../fonts/HDColton-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/****************Arabic Fonts***************/

@font-face {
	font-family: "Hacen Maghreb Bd";
	src: url("../fonts/arabic/HacenMaghrebBd.woff2") format("woff2"),
		url("../fonts/arabic/HacenMaghrebBd.woff") format("woff"),
		url("../fonts/arabic/HacenMaghrebBd.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Hacen Maghreb";
	src: url("../fonts/arabic/HacenMaghreb.woff2") format("woff2"),
		url("../fonts/arabic/HacenMaghreb.woff") format("woff"),
		url("../fonts/arabic/HacenMaghreb.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

:root {
	--regular: "HDColtonRegular";
	--medium: "HDColtonMedium";
	--semiBold: "HDColtonSemiBold";
	--bold: "HDColtonBold";

	--arRegular: "Hacen Maghreb Bd";
	--arBold: "Hacen Maghreb";
}